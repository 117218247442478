import { redirect } from 'react-router-dom'
import { isSessionValid } from '../isSessionValid'
import { getCurrentUser } from './api'

export async function authenticationLoader() {
  try {
    const currentUser = await getCurrentUser()
    if (!currentUser) {
      throw new Error('Unauthenticated')
    }
    if (currentUser?.role === 'RESIDENT') {
      if (isSessionValid()) {
        return null
      }
      throw new Error('Session Expired', { cause: 'session-expired-please-login-again' })
    }
    throw new Error('Not Permitted', { cause: 'user-is-not-a-tenant' })
  } catch (err) {
    if (err instanceof Error) {
      if (err.message.startsWith('Forbidden resource')) {
        const token = localStorage.getItem('token')
        const refreshToken = localStorage.getItem('refreshToken')
        localStorage.clear()
        throw redirect(`/auth/login${token || refreshToken ? '?error=session-expired-please-login-again' : ''}`)
      }
      if (err.cause === 'session-expired-please-login-again' || err.cause === 'user-is-not-a-tenant') {
        localStorage.clear()
        throw redirect(`/auth/login${err.cause ? `?error=${err.cause}` : ''}`)
      }
    }
    console.error('Error:', 'authenticationLoader.ts', err)
    return null
  }
}
