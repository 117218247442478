import { ResidentsUtils, Types } from '@walter/shared'
import { MenuItem, MenuStyles } from '@walter/shared-web'
import * as React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useConversations } from '../../contexts/Conversations'
import CurrentUserContext from '../../contexts/CurrentUser'
import ProjectContext from '../../contexts/Project'
import { t } from '../../utils/i18n'
import PropertyContext from '../../contexts/Property'

type Tool = {
  type?: Types.ModuleType
  label: string
  icon: Types.IconName
  slug: Types.ModuleSlug
  isActive: boolean
  hidden?: boolean
  notifications?: number
}

export default function Menu() {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { propertyId } = useParams<{ propertyId: string }>()

  const { currentResidentType, currentUser } = React.useContext(CurrentUserContext)
  const { currentProject } = React.useContext(ProjectContext)
  const { selectedProperty } = React.useContext(PropertyContext)

  const { currentUnreadMessagesCount } = useConversations()

  const allTools: Tool[] = React.useMemo(() => {
    return [
      {
        type: 'NOTIFICATION',
        label: t('menu:news'),
        icon: 'news',
        slug: 'news',
        isActive: true,
      },
      {
        type: 'CHAT',
        label: t('menu:chats'),
        icon: 'chat',
        slug: 'chat',
        isActive: true,
        notifications: currentUnreadMessagesCount,
      },
      {
        type: 'CALENDAR',
        label: t('menu:calendar'),
        icon: 'calendar',
        slug: 'calendar',
        isActive: true,
      },
      {
        type: 'CONTACT',
        label: t('menu:contacts'),
        icon: 'phone',
        slug: 'contacts',
        isActive: true,
      },
      {
        type: 'RULE',
        label: t('menu:rules'),
        icon: 'compose',
        slug: 'rules',
        isActive: true,
      },
      {
        type: 'AMENITY',
        label: t('menu:amenities'),
        icon: 'pool',
        slug: 'amenities',
        isActive: true,
      },
      {
        type: 'MAIL',
        label: t('menu:packages'),
        icon: 'package',
        slug: 'packages',
        isActive: true,
      },
      {
        type: 'WORK_ORDER',
        label: t('service-requests'),
        icon: 'wrench',
        slug: 'serviceRequests',
        isActive: true,
      },
      {
        type: 'FILE',
        label: t('menu:files'),
        icon: 'folder',
        slug: 'files',
        isActive: true,
      },
      {
        type: 'SALE',
        label: t('menu:marketplace'),
        icon: 'shop',
        slug: 'marketplace',
        isActive: true,
      },
      {
        type: 'FAQ',
        slug: 'faq',
        isActive: true,
        label: t('menu:faq'),
        icon: 'help',
      },
      {
        slug: 'accountStatements',
        isActive: true,
        label: t('menu:account-statements'),
        icon: 'receipt',
        hidden: !ResidentsUtils.residentCanSeeAccountStatements(
          currentResidentType,
          currentUser.ownedProperties,
          selectedProperty?.id ?? '',
        ),
      },
      {
        slug: 'otherResidents',
        isActive: true,
        label: t('menu:other-tenants'),
        icon: 'group',
      },
      {
        slug: 'unitDetails',
        isActive: true,
        label: t('menu:unit-details'),
        icon: 'house',
      },
    ]
  }, [currentResidentType, pathname, currentUnreadMessagesCount, propertyId])

  const validTools = React.useMemo(() => {
    if (currentProject) {
      return allTools.filter(
        (tool) => (!tool.type || currentProject.tools.find((aTool) => aTool === tool.type)) && !tool.hidden,
      )
    }
    return allTools
  }, [allTools])

  if (!currentProject) {
    return null
  }

  const slashIndex = pathname.lastIndexOf('/')
  const pageSlug = pathname.slice(slashIndex + 1)

  const isAToolPage = allTools.some((tool) => tool.slug === pageSlug)
  const isAnAllowedToolPage = validTools.some((tool) => tool.slug === pageSlug)

  if (isAToolPage && !isAnAllowedToolPage) {
    navigate(`/${propertyId}`)
  }

  return (
    <MenuStyles.MenuContainer>
      <MenuStyles.MenuSection>
        {validTools.map(({ label, icon, slug, notifications }) => (
          <MenuItem
            key={label}
            to={`/${propertyId}/${slug}`}
            isActive={pathname.includes(slug)}
            {...{ label, icon, slug, notifications }}
          />
        ))}
      </MenuStyles.MenuSection>
    </MenuStyles.MenuContainer>
  )
}
